<template>
  <div class="package-page">
    <b-container>
      <b-row>
        <div class="lang-container flex-middle">
          <div class="absolute-lang">
            <language/>
          </div>
        </div>
      </b-row>
      <b-row class="text-center">
        <b-col sm="1" md="1">
        </b-col>
        <b-col sm="10" md="10">
          <div>
            <img src="@/assets/images/logo/horizontal_1400.png" alt="sms2pro logo" class="sms-logo"/>
          </div>
          <div>
            <p style="font-size: 34px;">
              {{ $t('sell_package_list.topic') }}
            </p>
          </div>
          <div>
            <p class="font-size-14 ml-1 mr-1 pl-1 pr-1 ml-lg-5 mr-lg-5 pl-md-5 pr-md-5">
              {{ $t('sell_package_list.title') }}
              {{ $t('sell_package_list.detail') }}
              {{ $t('sell_package_list.note') }}
            </p>
          </div>
        </b-col>
        <b-col sm="1" md="1" class="text-right">
        </b-col>
      </b-row>
      <b-row v-if="is_loading">
        <b-col>
          <div class="text-center text-primary mt-5 mb-5 pt-5 pb-5">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong> {{ $t('loading') }}</strong>
          </div>
        </b-col>
      </b-row>
      <b-row v-else class="mt-4">
        <b-col v-for="item in packageList" :key="item._id" sm="12" md="6" lg="4">
          <div v-if="item.price_discount" class="package-card mb-4">
            <div v-if="item.is_popular" class="banner popular">
              {{ $t('sell_package_list.popular') }}
            </div>
            <div v-else-if="item.is_recommend" class="banner recommend">
              {{ $t('sell_package_list.recommend') }}
            </div>
            <div class="title discount" :class="`${item.planPrice > 100000 ? 'highlight' : ''}`">
              ฿{{ addComma(item.planPrice) }}
            </div>
            <div class="flex-center">
              <span class="title-discount" :class="`${item.price > 100000 ? 'highlight' : ''}`">
                ฿{{ addComma(item.price) }}
              </span>
              <span v-if="getPercentDiscount(item.price, item.price_discount)"
                    class="span-discount">-{{ getPercentDiscount(item.price, item.price_discount) }}%</span>
            </div>
            <div class="package-name mt-1">
              {{ item.name }}
            </div>
            <hr/>
            <div class="flex-center">
              <div>
                {{ $t('subscriptions.customPackage.numberOfCredit') }}
              </div>
              <div class="ml-auto text-value">
                {{
                  item.options.sms_max === -1 ? $t('common.Unlimited') : item.options.sms_max.toLocaleString()
                }}
              </div>
            </div>
            <div class="flex-center">
              <div>
                {{ $t('subscriptions.customPackage.price_or_message') }}
              </div>
              <div class="ml-auto text-value">
                {{
                  item.options.per_unit_price === -1 ? $t('common.Unlimited') : item.options.per_unit_price.toLocaleString()
                }}
              </div>
            </div>
            <div class="flex-center">
              <div>
                Tracking2pro
              </div>
              <div class="ml-auto text-value">
                <div class="text-center">
                  <div v-if="!item.premium_url_plan_id">{{ $t('subscriptions.no') }}</div>
                  <div v-else>{{ $t('subscriptions.yes') }}</div>
                </div>
              </div>
            </div>
            <div class="text-right mt-2">
              <b-button size="lg" pill variant="gradient" :disabled="is_loading" @click="pickupPackage(item)"
                        class="btn-custom" style="min-width: 130px;">
                {{ $t('sms.select').toUpperCase() }}
                <!-- {{ $t("subscriptions.subscriptionsNow").toUpperCase() }} -->
              </b-button>
            </div>
          </div>
          <div v-else class="package-card mb-4">
            <div v-if="item.is_popular" class="banner popular">
              {{ $t('sell_package_list.popular') }}
            </div>
            <div v-else-if="item.is_recommend" class="banner recommend">
              {{ $t('sell_package_list.recommend') }}
            </div>
            <div class="title" :class="`${item.planPrice > 100000 ? 'highlight' : ''}`">
              ฿{{ addComma(item.planPrice) }}
            </div>
            <div class="package-name">
              {{ item.name }}
            </div>
            <hr/>
            <div class="flex-center">
              <div>
                {{ $t('subscriptions.customPackage.numberOfCredit') }}
              </div>
              <div class="ml-auto text-value">
                {{
                  item.options.sms_max === -1 ? $t('common.Unlimited') : item.options.sms_max.toLocaleString()
                }}
              </div>
            </div>
            <div class="flex-center mt-1">
              <div>
                {{ $t('subscriptions.customPackage.price_or_message') }}
              </div>
              <div class="ml-auto text-value">
                {{
                  item.options.per_unit_price === -1 ? $t('common.Unlimited') : item.options.per_unit_price.toLocaleString()
                }}
              </div>
            </div>
            <div class="flex-center mt-1">
              <div>
                Tracking2pro
              </div>
              <div class="ml-auto text-value">
                <div class="text-center">
                  <div v-if="!item.premium_url_plan_id">{{ $t('subscriptions.no') }}</div>
                  <div v-else>{{ $t('subscriptions.yes') }}</div>
                </div>
              </div>
            </div>
            <div class="text-right mt-3">
              <b-button size="lg" pill variant="gradient" :disabled="is_loading" @click="pickupPackage(item)"
                        class="btn-custom" style="min-width: 130px;">
                {{ $t('sms.select').toUpperCase() }}
                <!-- {{ $t("subscriptions.subscriptionsNow").toUpperCase() }} -->
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="contact-box">
        <b-col xl="8 lg=8" md="8" sm="12">
          <div class="box-contact m-3">
            <div class="p-3" style="">
              <img src="@/assets/images/logo/horizontal_1400.png"
                   alt="sms2pro logo"
                   width="130px"
              />
              <h1 class="mt-2 text-color-g">
                {{$t('sell.topic')}}
              </h1>
              <h5 class="mt-2 text-color-g">
                {{$t('sell.detail')}}
              </h5>
              <hr style="border:0.01rem solid #88F0BE">
            </div>
            <div class="pl-3 ">
              <b-row align-v="center" class="mb-2">
                <b-col cols="auto">
                  <img src="@/assets/images/sell/call.svg"
                       alt="call"
                       width="40px"
                  />
                </b-col>
                <b-col >
                  <b-row align-v="center" class="mb-2">
                    <b-col cols="8">
                      <p class="m-0 p-0  text-color-g"> {{$t('sell.sale')}}</p>
                      <p class="m-0 p-0 text-bold">02-114-8318</p>
                      <p class="m-0 p-0"> {{$t('sell.sale_title')}}</p>
                    </b-col>
                    <b-col cols="auto"  align="right" v-if="checkMobile">
                      <a :href="telLink"   >
                      <b-button  size="sm" class="text-white " style="min-width: 74px" variant="outline-primary ">{{$t('sell.call')}}</b-button>
                      </a>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row align-v="center" class="mb-2">
                <b-col cols="auto">
                  <img src="@/assets/images/sell/line.svg"
                       alt="call"
                       width="40px"
                  />
                </b-col>
                <b-col >
                  <b-row align-v="center" class="mb-2">
                    <b-col cols="8">
                  <p class="m-0 p-0  text-color-g">{{$t('sell.cs')}}</p>
                  <p class="m-0 p-0 ">@sms2pro</p>
                    </b-col>
                    <b-col cols="auto" align="right">
                      <b-button size="sm" class="text-white "
                                style="min-width: 74px" variant="outline-primary"
                                @click="openURL('https://lin.ee/D9YX7rA')"
                      >
                        {{$t('sell.add_line')}}
                        </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row align-v="center" class="mb-2 pb-3">
                <b-col cols="auto">
                  <img src="@/assets/images/sell/email.svg"
                       alt="call"
                       width="40px"
                  />
                </b-col>

                <b-col >
                  <b-row align-v="center" class="mb-2">
                    <b-col cols="8">
                      <p class="m-0 p-0  text-color-g">   {{$t('sell.email')}}</p>
                      <p class="m-0 p-0">contact@sms2pro.com</p>
                    </b-col>
                    <b-col cols="auto" align="right">
                      <b-button size="sm" class="text-white" @click="openURL('https://sms2pro.com/contact-us-page/')" style="min-width: 74px" variant="outline-primary "> {{$t('sell.email_send')}}</b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col xl="4 lg=4" md="4" sm="0" class="contact-bg">
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" class="text-center">
          <p class="font-size-14 mt-4 mb-2">
            Copyright &copy; <span class="">{{ 'SMS2PRO' }}</span>
            {{ lastVersion || 'v.1.4.8' }}
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Language from "@/components/common/Language.vue";
import functionsCommon from "@/common/functions";
import packageApi from "@/repository/packageApi";

export default {
  components: {
    Language,
  },
  computed: {
    lastVersion() {
      return process.env.VUE_APP_ROOT_VERSION
    },
    telLink() {
      return `tel:${this.phoneNumber}`;
    },
    checkMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
  },
  data() {
    return {
      packageList: [],
      is_loading: false,
      phoneNumber: '021148318'
    }
  },
  mounted() {
    const tracking_channel = this.$route.query.tracking_channel
    if (tracking_channel) {
      this.setCookie('TRACKING_CHANNEL', tracking_channel, 30)
    }

    setTimeout(() => {
      this.getPackageList();
    }, 0)

    setTimeout(() => {
      if (this.packageList.length === 0) {
        this.getPackageList();
      }
    }, 1500)
  },
  methods: {
    getPackageList() {
      this.is_loading = true;
      packageApi.getPublicList(_.cloneDeep(this.search)).then(response => {
        this.packageList = response.data
      }).catch(err => {
        throw err
      }).finally(() => {
        this.is_loading = false
      });
    },
    addComma(value) {
      return value ? value?.toLocaleString() : 0
    },
    openURL(url) {
      window.open(url), "_blank";
    },
    pickupPackage(item) {
      const primary_color = '#19cbac';
      const secondary_color = '#067f6d';
      this.$store.dispatch('theme/setThemeColor', {
        primary: primary_color,
        secondary: secondary_color
      });

      this.$swal.fire({
        icon: 'info',
        title: this.$t('sell_package_list.doYouHaveAccount'),
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.$t('sell_package_list.registerNewAcc'),
        confirmButtonText: this.$t('sell_package_list.signIn'),
        confirmButtonColor: primary_color,
        cancelButtonColor: secondary_color,
        showCloseButton: true,
      }).then(result => {
        if (result.isConfirmed) { // has account
          const package_id = item._id ? 'package_id=' + item._id : ''
          const tracking_channel = this.$route.query.tracking_channel
          const tracking_channel_query = tracking_channel ? `&tracking_channel=${tracking_channel}` : ''
          const redirectPath = `/Loading?${package_id}&go_to=checkout${tracking_channel_query}`
          this.$router.push(redirectPath);
        } else if (result.dismiss === 'cancel') {
          const package_id = item._id ? 'package_id=' + item._id : ''
          const premium_url = item.premium_url_plan_id ? '&premium_url=true' : ''
          const redirectPath = `/Sell/Register?${package_id}${premium_url}`
          this.$router.push(redirectPath);
        }
      })
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "==" + (value || "") + expires + "; path=/";
    },
    getCookie(c_name) {
      return functionsCommon.getCookie(c_name)
    },
    getPercentDiscount(price, price_discount) {
      const discount = (100 - (1 - (price_discount / price)) * 100)
      const discountStr = discount.toFixed(2)
      if (discountStr === '0.00') {
        return ''
      } else if (discountStr.slice(-2) === '00') {
        return discountStr.slice(0, -3)
      } else if (discountStr.slice(-1) === '0') {
        return discountStr.slice(0, -1)
      } else {
        return discountStr
      }
    },
  },
}
</script>
<style scoped lang="scss">

.package-page {
  padding: 42px;
  min-height: 100dvh;
  background: radial-gradient(62.08% 62.08% at 50% 118.4%, #5BC0A6 0%, #212529 100%);
  color: #fff;

  @media (max-width: 700px) {
    padding: 32px;
  }

  @media (max-width: 520px) {
    padding: 16px 8px;
  }
}

.package-card {
  border-radius: 10px;
  background: #000;
  border-radius: 10px;
  padding: 12px 30px 24px;
  transition: 0.3s;
  font-size: 14px;
  color: #fff;
  position: relative;
  overflow: hidden;
  min-height: 286px;

  .banner {
    position: absolute;
    top: 20px;
    right: -60px;
    color: #fff;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 700;
    transform: rotate(45deg);
    width: 200px;
    text-align: center;
  }

  .banner.popular {
    background: linear-gradient(90deg, #FF4A4A 0%, #FDAE37 100%);
  }

  .banner.recommend {
    background: linear-gradient(90deg, #5CC4FF 0%, #9747FF 100%);
  }

  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  }

  .title {
    font-size: 48px;
    font-weight: 700;
    white-space: nowrap;
  }

  .title.discount {
    line-height: 4rem;
  }

  .title-discount {
    font-size: 24px;
    font-weight: 600;
    color: #9F9F9F;
    white-space: nowrap;
    text-decoration: line-through;
    line-height: 1.2rem;
  }

  .span-discount {
    color: #D30000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border-radius: 4px;
    background: #FEF0AE;
    padding: 0 4px 0 6px;
    margin-left: 12px;
  }

  .title.highlight {
    background: linear-gradient(180deg, #FFF 0%, #FEF0AE 56.5%, #FBC56D 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .package-name {
    font-size: 14px;
    font-weight: 500;
  }

  hr {
    border: 1px solid #19CBAC;
    border-width: 1px 0 0;
    margin: 10px 0 16px;
  }

  .text-value {
    color: #19CBAC;
    text-align: right;
    font-size: 14px;
    font-weight: 700;
  }

  .btn-custom {
    color: #000;
    font-size: 18px;
    padding: 6px 16px;
  }
}

.sms-logo {
  margin-bottom: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  max-width: 410px;

  @media (max-width: 700px) {
    padding-top: 26px;
  }

  @media (max-width: 520px) {
    padding-top: 38px;
  }
}

.absolute-lang {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 0 4px;
}

.lang-container {
  position: relative;
  width: 100%;
  z-index: 99;
}
</style>

<style lang="scss">
.lang-container {
  .navbar-nav .nav-link {
    padding: 2px 4px 2px 6px;
  }

  .text-grey {
    color: #fff;
    margin: 0;
    margin-top: 0 !important;
  }
}

.contact-box {
  background: #00BE9E;
  border-radius: 10px;
}

.box-contact {
  background: #323743;
  box-shadow: 0px 2px 10px 0px #58BBA233;
  border-radius: 10px;
}

.box-hr {
  border-bottom: 1px solid;
  border-image-source: linear-gradient(180deg, #358760 0%, #79C8A2 100%);
}

.text-color-g {
  background: linear-gradient(180deg, #88F0BE 0%, #AFD1C1 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact-bg{
  background-image: url('../../assets/images/sell/bg.svg')!important;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size:contain;
}

</style>